.full-free-collection-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        grid-template-columns: 1fr 1fr;            
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;            
    }
}

.full-free-collection-pagination {
    display: inline-flex;

    li {
        margin-right: 15px;

        a {
            display: block;
            font-size: 16px;
            
            .pagination-icon {
                background-color: #1682C5;
                border-radius: 30px;
                height: 42px;
                width: 42px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                &.prev {
                    transform: rotate(-90deg);
                }
                
                &.next {
                    transform: rotate(90deg);
                }
                
                svg {
                    height: 20px;
                    width: 20px;
                }
            }

            .pagination-text {
                display: block;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}