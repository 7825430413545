.privacy-policy-form-section {
    position: relative;
    z-index: 0;
 
    .privacy-policy-form-box {
        box-shadow: 0px 0px 20px 8px rgba(0,0,0,0.08);
        border-radius: 15px;
        padding: 50px;
        background-color: #ffffff;
    }

}