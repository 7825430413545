@import "./bulma/sass/utilities/initial-variables.sass";
@import "./bulma/sass/utilities/functions.sass";

$primary: #1682C5;
$primary-red: #ED1C24;
$primary-red-invert: findColorInvert($primary-red);
$primary-yellow: #EBDC00;
$primary-yellow-invert: findColorInvert($primary-yellow);

@import "./bulma/sass/utilities/derived-variables.sass";

// Add new custom colors
$addColors: (
    "primary-red":($primary-red, $primary-red-invert),
    "primary-yellow":($primary-yellow, $primary-yellow-invert),
);
$colors: map-merge($colors, $addColors);

// Add custom space
$spacing-values: (
    "0": 0, 
    "5px": 5px, 
    "10px": 10px, 
    "15px": 15px, 
    "20px": 20px, 
    "25px": 25px, 
    "30px": 30px, 
    "35px": 35px, 
    "40px": 40px, 
    "50px": 50px, 
    "60px": 60px, 
    "100px": 100px,
    "130px": 130px,
    "150px": 150px,
);

$radius: 10px;
$control-radius: $radius;

// $size-1: 3rem;
// $size-2: 2.5rem;
// $size-3: 2rem;
// $size-4: 1.5rem;
// $size-5: 1.25rem;
// $size-6: 1rem;
// $size-7: 0.75rem;
$size-1: 9px;
$size-2: 12px;
$size-3: 13px;
$size-4: 14px;
$size-5: 15px;
$size-6: 16px;
$size-7: 17px;
$size-8: 18px;
$size-9: 19px;
$size-10: 20px;
$size-11: 21px;
$size-12: 22px;
$size-13: 23px;
$size-14: 24px;
$size-15: 25px;
$size-16: 26px;
$size-17: 27px;
$size-18: 28px;
$size-19: 30px;
$size-20: 32px;
$size-21: 33px;
$size-22: 36px;
$size-23: 40px;
$size-24: 42px;
$size-25: 44px;
$size-26: 50px;
$size-27: 53px;
$size-28: 57px;
$size-29: 60px;
$size-30: 62px;
$size-31: 63px;
$size-32: 70px;
$size-33: 72px;
$size-34: 85px;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 $size-9 $size-10 $size-11 $size-12 $size-13 $size-14 $size-15 $size-16 $size-17 $size-18 $size-19 $size-20 $size-21 $size-22 $size-23 $size-24 $size-25 $size-26 $size-27 $size-28 $size-29 $size-30 $size-31 $size-32 $size-33 $size-34;

// $size-100: 100px;
// $sizes: $size-100;

@import "./bulma/bulma.sass";