.hand-red-grid-card-shape {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    @media screen and (max-width: 1023px) {
        display: none;
    }

    .hand-shape-image {
        width: auto;
        position: absolute;
        top: -20px;
        left: -40px;
        bottom: 0;
        z-index: 1;
        width: 340px;
        .react-parallax  {
            height: 100%;
            width: 100%;
            overflow: visible !important;
        }
    }

    .red-grid-card-image {
        position: absolute;
        top: 60%;
        left: 130px;
        z-index: 0;
        width: 310px;
        .react-parallax  {
            height: 100%;
            width: 100%;
            overflow: visible !important;
        }
    }

    .red-grid-card-right-top-image {
        position: absolute;
        top: -150px;
        right: 30px;
        z-index: 0;
        width: 350px;
        .react-parallax  {
            height: 100%;
            width: 100%;
            overflow: visible !important;
        }
    }

}

.bg-gray-light {
    background-color: #f4f4f4;
}

