.teaching-section-heading {
  br {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.cards {
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.cards-image-container {
  @media screen and (max-width: 800px) {
    flex: 100%;
  }
  @media screen and (min-width: 901px) {
    flex: 40%;
  }
}

.container-teaching {
  @media screen and (max-width: 800px) {
    flex: 100%;
  }
  @media screen and (min-width: 901px) {
    flex: 60%;
  }
}
