.checkout-shopping-cart-list {
  .checkout-shopping-cart-item {
    display: grid;
    grid-template-columns: 30px 254px 1fr;
    grid-gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    .checkout-shopping-cart-item-image {
      width: 254px;
      height: 164px;
    }
  }

  &.complete-set-list {
    .checkout-shopping-cart-item {
      grid-template-columns: 164px 1fr;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .checkout-shopping-cart-item-image {
      width: 164px;
      height: auto;
    }
  }
}

.loadingText {
  position: absolute;
  text-align: center;
  width: 100vw;
  font-size: 24pt;
  z-index: 10000;
  color: #f1f1f1;
}

.checkout-shopping-cart-pagination {
  display: inline-flex;

  li {
    margin-right: 15px;

    a {
      display: block;
      font-size: 16px;

      .pagination-icon {
        // background-color: #1682C5;
        border-radius: 30px;
        // height: 42px;
        // width: 42px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;

        &.prev {
          transform: rotate(0deg);
        }

        &.next {
          transform: rotate(-180deg);
        }

        svg {
          height: 28px;
          width: 28px;
        }
      }

      .pagination-text {
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.checkout-shopping-cart-box {
  box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}
