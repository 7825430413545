.author-section {
    background-color: #f4f4f4;
    @media screen and (max-width: 991px) {
        overflow: hidden;
    }
}

.sky-blue-card {
   background-color: #00b9ed;
}

.about-author-red-grid-card-shape {
    position: absolute;
    top: 55%;
    right: -80px;
    width: 450px;
    // overflow-x: hidden;

    .react-parallax  {
        height: 100%;
        width: 100%;
        overflow: visible !important;
    }

    @media screen and (max-width: 1023px) {
        top: 90%;
        bottom: 0;
        right: 10px;
        width: 200px;
    }
    
    @media screen and (max-width: 991px) {
        display: none;
    }

}