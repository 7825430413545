.lessons-page-category-menu {
    ul {
        display: flex;
        align-items: center;
        // justify-content: space-between;

        li {
            margin-right: 20px;
            a {
                display: block;
                font-size: 20px;
                font-weight: 500;
                color: #1682C5;
                padding: 10px 25px;
                // text-transform: uppercase;
                &:hover, &.active {
                    color: #ED1C24;
                }
            }
        }

        @media screen and (max-width: 1023px) {
            display: block;
            // li {
            //     a {
            //         font-size: 22px;
            //     }
            // }
        }

    }
}