// .featured-freebies-section {
    // background-color: #f4f4f4;
// }
.featured-articles-right-panel {
    padding: 5px 30px;
    border-radius: 15px;
    background-color: #f6f6f6;
    box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.08);

    .panel-box {
        padding-top: 30px;
        padding-bottom: 30px;
        &:not(:last-child){
            // margin-bottom: 15px;
            border-bottom: 1px solid #d1d1d1;
        }
        .panel-list {
            li {
                
                a {
                    font-size: 16px;
                    color: #2b2b2b;
                    padding: 1px 0px;
                    
                    &:hover {
                        color: #1981c4;
                    }
                }

            }
        }
    }
}