.page-tabs-menu {
    ul {
        display: flex;
        align-items: center;
        border-bottom: transparent;
        @media screen and (max-width: 1023px) {
            display: block;
        }
        li {
            margin-right: 15px;
            a {
                display: block;
                font-size: 20px;
                font-weight: 500;
                color: #1682C5;
                padding: 10px 15px;
                // text-transform: uppercase;
                border-bottom: transparent;
                &:hover, &.is-active, &.active {
                    color: #ED1C24;
                }
            }
        }
    }
}

.page-section-menu-tabs {
    display: flex;
    align-items: center;
    border-bottom: transparent;
    @media screen and (max-width: 1023px) {
        display: block;
    }
    li {
        // margin-right: 15px;
        display: block;
        font-size: 20px;
        font-weight: 500;
        color: #1682C5;
        padding: 10px 15px;
        // text-transform: uppercase;
        border-bottom: transparent;
        transition: .4s all ease-in-out;

        &:hover, &.react-tabs__tab--selected {
            color: #ED1C24;
        }
        &.react-tabs__tab--selected {
            background-color: transparent;
            border-color: transparent;
        }
        &:focus {
            border-color: transparent;
            box-shadow: none;
            &:after {
                background: transparent;
            }
        }
    }
}

.tabs-container {
    .tabs-panel {
        display: none;
        &.active {
            display: block;
        }
    }
}