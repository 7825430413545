.courses-section {
    padding-top: 50px;
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .video-conference-image {
        position: relative;
        z-index: 2;
        @media screen and (max-width: 767px) {
            width: 70%;
            left: 15px;
        }
    }

    .conference-workshop-image {
        margin-top: -50px;
        margin-left: 90px;
        @media screen and (max-width: 767px) {
            margin-top: -45px;
            margin-left: 15px;
        }
    }

    .bottom-line {
        margin-bottom: -75px;
    }

}

.courses-card-section {
    margin-bottom: 60px !important;

    @media screen and (max-width: 991px) {
        .slick-slider {
            padding-left: 35px;
            padding-right: 35px;

            .slick-prev {
                left: 4px;
            }
            .slick-next {
                right: 0px;
            }
        }
    }
}

.courses-flow-card {
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 7px rgba(0,0,0,0.08);
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    position: relative;

    @media screen and (max-width: 991px) {
        margin: 0 4px;
    }

    .course-icon {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            margin: 0 auto;
        }
    }

    .course-arrow {
        // background-color: #00ccdd;
        height: 48px;
        width: 48px;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -25px;
        left: 0;
        right: 0;
        margin: 0 auto;

        svg {
            transition: .3s all ease-in-out;
            transform: rotate(180deg);
        }
    }

    .course-title {
        font-size: 18px;
    }

    .course-details {
        font-size: 15px;
        max-height: 0;
        transition: max-height 1s ease-out;
        overflow: hidden;
    }

    &:hover {
        
        .course-details {
            max-height: 500px;
            transition: max-height 0.6s ease-in;
        }

        .course-arrow {
            svg {
                transform: rotate(0deg);
            }
        }
    }
}

.curses-section-bg {
    position: absolute;
    top: 0;
    right: -230px;
    bottom: 0;
    width: 500px;
    z-index: -1;

    @media screen and (max-width: 767px) {
        display: none; 
    }

    .react-parallax  {
        height: 100%;
        width: 100%;
    }
}

