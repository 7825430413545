.address-card {
  background-color: #1682c5;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  height: 100%;
  color: #ffffff;
  box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.1);
  h6 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 15px;
    small {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.at {
  font-family: "Roboto" !important;
}
