// .footer-section {
//     background-color: #1682C5;
//     padding: 100px 0;

//     .footer-heading, .footer-copyright {
//         color: #ffffff;
//     }

//     .footer-heading {
//         font-size: 28px;
//         font-weight: bold;
//         border-bottom: 1px solid rgba(#ffffff, 0.33);
//         padding-bottom: 20px;
//     }
//     .footer-copyleft{
//         float: left;
//         font-size: 12px;
//         color: #ffffff;
//         padding-top: 20px;    
//     }
    
//     .footer-copyright {
//         font-size: 12px;
//         // font-weight: bold;
//         border-top: 1px solid rgba(#ffffff, 0.33);
//         padding-top: 20px;
//     }
//     .my-30px {
//         margin-top: 15px !important;
//         margin-bottom: 15px !important;
//     }
//     .footer-social-media{
//         a {
//             margin-right: 10px;
//             // svg {

//             // }
//         }
//     }
    
// }

.footer-section {
    background-color: #1682C5;
    padding: 100px 0;

    @media screen and (max-width:1023px) {
        padding: 60px 0;
    }

    .footer-heading, .footer-copyright {
        color: #ffffff;
    }

    .footer-heading {
        font-size: 28px;
        font-weight: bold;
        border-bottom: 1px solid rgba(#ffffff, 0.33);
        padding-bottom: 20px;
    }

    .footer-copyright {
        font-size: 12px;
        // font-weight: bold;
        border-top: 1px solid rgba(#ffffff, 0.33);
        padding-top: 20px;

        @media screen and (min-width: 992px) {   
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

    }

    .footer-social-media{
        a {
            margin-right: 10px;
            // svg {

            // }
        }
    }
    
}