.about-hero-section {
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.about-hero-bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    // height: 500px;
    width: 15%;

    @media screen and (max-width: 1023px) {
        top: 60%;
        right: 0;
        bottom: -50px;
        height: 500px;
        width: 20%;
    }

    .react-parallax  {
        height: 100%;
        width: 100%;
        top: 30%;
        overflow: visible !important;
    }
}