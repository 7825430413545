.interactive-lessons-section {
    background-color: #f4f4f4;
}

.interactive-lessons-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;


    .interactive-lessons-card-1 {
        grid-column: span 2 / span 2;
    }

    @media screen and (max-width: 1023px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        .interactive-lessons-card-1 {
            grid-column: span 1;
        }
    }
}