a.button {
  // border-radius: 10px !important;
  // line-height: 0.8;
  transition: .2s all ease-in-out;
  &.primary-link-button {
    &:hover, &:focus {
      background-color:  #ED1C24 !important;
    }
  }
}

.button, a.button {
  &.button-with-right-arrow {
    background-image: none;
    background-position: center right 10px;
    background-size: 19px 19px;
    background-repeat: no-repeat;
    transition: .4s all ease-in-out;
    
    &:hover {
      padding-right: 40px;
      // background-image: url('../../assets/images/svg/ic_button_arrow.svg');
      background-image: url('../../assets/images/icon/ic-right-arrow.png');
    }
    
    &.blue-icon {
      
      &:hover {
        background-image: url('../../assets/images/icon/ic-blue-right-arrow.png');
      }
    }
  }
}