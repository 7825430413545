.font-family-default {
    font-family: var(--default-font-family) !important;
}

.smooth-scroll {
    scroll-behavior: smooth;
}

.radius-10px {
    border-radius: 10px !important;
}

.border-t {
    border-top: 1px solid;
}

.border-b {
    border-bottom: 1px solid;
}

.border-dark {
    border-bottom-color: #464646;
}

.z-index-0 {
    z-index: 0;
}

.z-index-2 {
    z-index: 2;
}

.box-shadow {
    box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.11);
}

.dash-line {
    @media screen and (max-width: 1023px) {
        display: none;
    }
}

.order {
    &-0 {
        order: 0;
    }
    &-1 {
        order: 1;
    }

    @media screen and (max-width: 767px) {
        &-0-mobile {
            order: 0;
        }   
        &-1-mobile {
            order: 1;
        }   
    }

    @media screen and (min-width: 768px)  {
        &-0-tablet {
            order: 0;
        }   
        &-1-tablet {
            order: 1;
        }   
    }

    @media screen and (min-width: 1024px) {
        &-0-desktop {
            order: 0;
        }   
        &-1-desktop {
            order: 1;
        }   
    }

    // &-2 {
    //     order: 2;
    // }
    // &-3 {
    //     order: 3;
    // }
}