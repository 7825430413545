.product-reviews-section {
    background-color: #f4f4f4;   
}

.product-review-card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    .product-review-card-item {
        background-color: #ffffff;
        border-radius: 15px;
        padding: 30px;
        box-shadow: 0px 0px 20px 7px rgba(0,0,0,0.08);
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
    }

}

.bg-top-line {
    margin-bottom: -25px;
}