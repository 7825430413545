.form-group {
    .control {

        input {
          &::placeholder {
            text-transform: uppercase;
          }
        }

        input:not([type="checkbox"]) {
            background-color: #f4f4f4;
            border-radius: 10px;
            color: #636363;
            font-size: 18px;
            font-weight: 500;
            padding: 14px 22px;
            height: 50px;
            box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
        }

        textarea {
            background-color: #f4f4f4;
            border-radius: 10px;
            color: #636363;
            font-size: 18px;
            font-weight: 500;
            padding: 14px 22px;
            // height: 50px;
            box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
        }

        .select {
            width: 100%;
            height: 50px;
            margin-bottom: 8px;
            
            &:after {
              display: none;
            }

            select {
              background-color: #f4f4f4;
              border-radius: 10px;
              color: #636363;
              font-size: 18px;
              font-weight: 500;
              padding: 10px 22px;
              height: 50px;
              box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
              background: #f4f4f4 url('../../assets/images/icon/ic-select-toggle.png') no-repeat right .75rem center/18px 10px;
            }

        }

        &.checkbox-control {
          position: relative;
          input[type="checkbox"] {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            visibility: hidden;
          }
          .checkbox-label {
            color: #636363;
            font-size: 18px;
            font-weight: 500;
            padding-left: 30px;
            line-height: 20px;
            height: 20px;
            cursor: pointer;
            display: inline-block;
            margin: 0;
            &:after {
              content: '';
              width: 20px;
              height: 20px;
              border-radius: 5px;
              border: 1px solid #636363;
              position: absolute;
              left: 0;
              background-color: #f4f4f4;
            }
            &:before {
              content: '';
              display: none;
              width: 8px;
              height: 13px;
              border-radius: 2px;
              border: 3px solid #636363;
              border-left: 0;
              border-top: 0;
              position: absolute;
              left: 6px;
              top: 3px;
              transform: rotate(45deg);
              z-index: 1;
            }
          }
          input[type="checkbox"] {
            &:checked {
              ~ .checkbox-label {
                &:before {
                  display: block;
                }
              }
            }
          }
        }

    }

}

.default-form-group {
  
  .control {

    input:not([type="checkbox"]) {
        // background-color: #f4f4f4;
        border-radius: 0;
        color: #636363;
        font-size: 18px;
        // font-weight: 500;
        padding: 15px 0px;
        height: 50px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #b5b5b5;
        
        &:hover, &.active, &:focus {
          outline: none;
          box-shadow: none;
          border-bottom-color: #000000;
        } 
    }

  }

}

.breadcrumb {
  ul {
    li {
      a {
        font-size: 20px;
        @media screen and (max-width:1023px ) {
          font-size: 16px;
        }
      }
    }
  }
}