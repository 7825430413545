.games-section {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #f4f4f4;
    position: relative;
    @media screen and (max-width: 1023px) {
        padding-top: 280px;
    }
}

.bg-shape-left-side {
    position: absolute;
    left: 0;
    top: -60px;
    bottom: -60px;
    > img  {
        width: 88%;
    }
    @media screen and (min-width: 767px) and (max-width: 1024px) {
        width: 55%;
    }
}