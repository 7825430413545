.contact-form-section {
 
    .contact-form-box {
        box-shadow: 0px 0px 20px 8px rgba(0,0,0,0.08);
        border-radius: 15px;
        padding: 50px;
        @media screen and (max-width: 1023px) {
            padding: 50px 30px;
        }
    }
}