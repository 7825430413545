.owner-section {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #f4f4f4;
}

.owner-small-img  {
    border-radius: 50px;
}

.owner-large-img  {
    border-radius: 25px;
} 

.owner-box-image-desktop {
    display: block;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.owner-box-image-mobile {
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
    }
}