::selection {
  color: var(--white-color);
  background: var(--primary-color);
}

html,
body {
  width: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  font-weight: 400;
  position: relative;
  color: rgba(0, 0, 0, 0.7);
  font-size: var(--font-size-regular);
  font-family: var(--default-font-family) !important;
  background-color: #ffffff;

  &.menu-is-active {
    overflow: hidden;
  }
}

.dashboard-layout {
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    "nav head"
    "nav  main"
    "footer footer";
}

a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;

  &:hover {
    text-decoration: none;
  }
  &.button {
    font-family: var(--default-font-family);
  }

  span {
    font-family: "Roboto" !important;
  }
}

img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  br {
    display: none;
  }
}

* {
  font-family: var(--default-font-family) !important;
}
