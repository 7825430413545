.teach-courses-section {

    .bottom-line {
        margin-bottom: -60px;
    }
}

.teach-courses-card-section {
    margin-bottom: 60px !important;
}

.owner-large-img  {
    border-radius: 25px;
} 


.teach-courses-flow-card {
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 7px rgba(0,0,0,0.08);
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    position: relative;

    .course-icon {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            margin: 0 auto;
        }
    }

    .course-arrow {
        // background-color: #00ccdd;
        height: 48px;
        width: 48px;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -25px;
        left: 0;
        right: 0;
        margin: 0 auto;

        svg {
            transition: .3s all ease-in-out;
            transform: rotate(180deg);
        }
    }

    .course-details {
        max-height: 0;
        transition: max-height 1s ease-out;
        overflow: hidden;
    }

    &:hover {
        
        .course-details {
            max-height: 500px;
            transition: max-height 0.6s ease-in;
        }

        .course-arrow {
            svg {
                transform: rotate(0deg);
            }
        }
    }
}