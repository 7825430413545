// .our-philosophy-section {
//     background-color: #f4f4f4;
// }

.bottom-line {
    margin-bottom: -50px;
}

.owner-small-img  {
    border-radius: 50px;
}


.custom-accordion-box{
    *:focus {
        outline: none;
    }
    .accordion {
        border: none;
        .accordion__heading {
            .accordion__button {
                background-color: #ffffff;
                font-weight: bold;
                font-size: 18px;
                border-radius: 10px;
                position: relative;
                padding-left: 30px;
                padding-right: 45px;
                padding-top: 12px;
                padding-bottom: 12px;

                &::before {
                    content: '';
                    display: none;
                }

                &::after {
                    content: '';
                    background-image: url('./images/ic-select-toggle.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: auto 50%;
                    position: absolute;
                    top: 30%;
                    right: 15px;
                    width: 25px;
                    height: 24px;
                }

                &[aria-expanded='true'], &[aria-selected='true'] {
                    background-color: #EBDC00;
                    &::after {
                        transform: rotate(180deg);
                    }
                }

            }
        }
        .accordion__item {
            box-shadow: 0px 0px 20px 7px rgba(0,0,0,0.08);
            border-radius: 10px;

            &+ .accordion__item {
                border-top: none;
                margin-top: 15px;
            } 
        }
        .accordion__panel {
            padding: 30px;
            p {
                font-size: 16px;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
            }
        }
    }
}