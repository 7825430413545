.download-section {
    background-color: #f4f4f4;
    position: relative;

    .download-link {
        background-color:transparent;
        color:  #2A2A2A;
        border-color:  transparent;
        display: inline-flex;
        align-items: center;
        transition: .4s all ease-in-out;
        white-space: break-spaces;
        text-align: left;
        line-height: 22px;
        height: auto;


        &:hover, &:focus {
            box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.08);
            background-color: #ffffff;
            border-color: transparent;
            color:  #2A2A2A;
            outline: none;
        }
        &:hover {
            transform: translateX(20px);
        }
        svg{
            width: 16px;
            height: 16px;
        }
    }

    .download-background-right {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 290px;

        .download-background-top {
            position: absolute;
            top: -190px;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: visible !important;
        }

        .download-background-bottom {
            position: absolute !important;
            top: 80%;
            right: 0;
            width: 100%;
            height: 100%;
            overflow: visible !important;
        }

        @media screen and (max-width: 767px) {
            width: 170px;

            .download-background-top {
                top: -100px;
            }
    
            .download-background-bottom {
                top: 91%;
                // bottom: -500px;
            }
        }


    }

}
