header {
    z-index: 10;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: .4s all ease-in-out;

    .header-top {
        background-color:  #EBDC00;
        padding: 4px 0px;
        transition: .5s all ease-in-out;
    }

    .add-to-card {
        display: inline-block;
        margin-left: 20px;
        position: relative;
        svg {
            height: 20px;
            width: 20px;
            vertical-align: middle;
        }
        .add-to-card-number {
            background-color: #0a0a0a;
            height: 15px;
            min-width: 15px;
            border-radius: 30px;
            color: #ffffff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -2px;
            left: 12px;
            font-size: 10px;
            font-weight: bold;
            padding: 0 4px;
        }
    }

    .header-bottom-menu-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-site-logo {
        display: flex;
        align-items: center;
        .site-logo {
            width: 168px;
        }
    }

    .header-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: .8s all ease-in-out;
        .header-menu-item {
            .header-menu-link {
                padding: 25px;
                font-size: 16px;
                font-weight: 600;
                color: #fff;
                transition: .4s all ease-in-out;
                position: relative;
                z-index: 0;
                display: block;

                @media screen and (min-width: 1024px) and (max-width: 1215px) {
                    padding: 16px;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    transform: skew(-8deg);
                    transition: .4s all ease-in-out;
                    // background-position: center center;
                    // background-size: contain;
                    // background-repeat: no-repeat;
                }

                &:hover, &.active {
                    color: #2A2A2A;
                    &::after {
                        background-color:  #EBDC00;
                        // background-image: url('./images/highlight.svg');

                    }
                }
            }
        }
    }

    &.scrolled {
        box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.14);
        transition: .5s all ease-in-out;
        background-color: #ffffff;

        .header-bottom {
            .header-menu {
                .header-menu-item {
                    .header-menu-link {
                        color: #2A2A2A;
                        &:hover {
                            color: #2A2A2A;
                        }
                        &.active {
                            background-color:  #EBDC00;
                            
                        }
                    }
                }
            }
        }
    }

    .hamburger {
        display: none;
        position: absolute;
        top: 22px;
        left: 30px;

        .line {
            width: 30px;
            height: 4px;
            background-color: #ecf0f1;
            display: block;
            margin: 6px auto;
            transition: all 0.3s ease-in-out;
        }

        &.is-active .line:nth-child(1){
            transform: translateY(10px) rotate(45deg);
        }
        
        &.is-active .line:nth-child(2){
            opacity: 0;
        }

        &.is-active .line:nth-child(3){
            transform: translateY(-10px) rotate(-45deg);
        }
    }

    .header-search {
        display: none;
    }

    @media screen and (max-width: 1023px) {

        height: 80px;
        transition: .5s all ease-in-out;

        &.scrolled {
            transition: .5s all ease-in-out;
            background-color: #2A2A2A;
        }

        .hamburger {
            display: block;
        }

        .header-top {
            display: none;
        }

        .header-bottom-menu-container {
            justify-content: center;
        }
        
        .header-site-logo {
            order: 1;
            z-index: 1;
            .site-logo {
                width: 130px;
            }
        }
        
        .header-menu-bar {
            order: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .header-menu {
            display: block;
            margin-top: -1000px;
            height: 0;
            visibility: hidden;
            transition: .5s all ease-in-out;
    
            .header-menu-item {
                .header-menu-link {
                    padding: 15px 25px;
                    color:#ffffff !important;
                    &::after {
                        transform: skew(0deg);
                    }
                }
            }
        }

        &.show {
            height: 100vh;
            background-color: #2A2A2A;
            .header-menu {
                display: block;
                height: 100%;
                visibility: visible;
                margin-top: 80px;
            }
        }

        .header-search {
            display: block;
            background-image: url('./images/ic-search.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 25px;
            width: 25px;
            order: 2;
            position: absolute;
            right: 25px;
        }
            
    }
}

.menu-open{
    overflow: hidden !important;
}

.menu-active {
    color: #2A2A2A;
    &::after {
        background-color:  #EBDC00 !important;
        // background-image: url('./images/highlight.svg');

    }
}