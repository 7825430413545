.lession-video-details-section {

    .top-line {
        margin-bottom: -40px;
    }

    .read-more-link {
        svg {
            width: 20px;
            height: 20px;
            margin-left: 15px;
        }
    }

    .share-link {
        border-color:  transparent;
        border-radius: 8px;
        padding: 5px 15px;
        &:hover{
            box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.08);
        }
        svg {
            width: 15px;
            height: 15px;
            margin-left: 10px;
        }
    }

    .freebie-card-placeholder {
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 7px rgba(0,0,0,0.08);
        img {
            width: 100%;
        }
    }

    .lession-video-details-box {
        box-shadow: 0px 0px 20px 8px rgba(0,0,0,0.08);
        border-radius: 15px;
        padding: 30px;
    }
}
