.store-page-category-menu {
    ul {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        @media screen and (max-width: 1023px) {
            display: block;
        }
        li {
            margin-right: 15px;
            a {
                display: block;
                font-size: 20px;
                font-weight: 500;
                color: #1682C5;
                padding: 10px 15px;
                // text-transform: uppercase;
                &:hover, &.active {
                    color: #ED1C24;
                }
            }
        }
    }
}