.home-hero-section {
  // background-image: url('./images/hero-hand.png');
  // background-position: bottom right;
  // background-repeat: no-repeat;
  // background-size: auto 75%;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.home-hero-bg-image {
  position: absolute;
  right: 0;
  bottom: -50px;
  height: 450px;
  width: 700px;
  z-index: -1;
  .react-parallax {
    height: 100%;
    width: 100%;
    top: 0;
    overflow: visible !important;
  }

  @media screen and (max-width: 767px) {
    right: -200px;
    bottom: -230px;
  }

  @media screen and (max-width: 1200px) {
    right: -200px;
    bottom: -230px;
  }
}
