:root {
  --header-font-family: 'DINPro', sans-serif;
  --default-font-family: 'DINPro', sans-serif;

  --font-size-small: 13px;
  --font-size-medium-small: 14px;
  --font-size-regular: 16px;
  --font-size-medium: 18px;
  --font-size-large: 24px;
  --font-size-medium-large: 26px;
  --font-size-x-large: 32px;
  --font-size-xx-large: 48px;

  --primary-color : #1682C5;
  --primary-yellow-color : #EBDC00;
  --primary-red-color : #ED1C24;
  --secondary-color : #2A2A2A;
  --bg-main-color: #F4F4F4;
  --white-color: #ffffff;

  // --danger-color : #FF7C7C;
  // --light-color : #F1F3F9;
  // --gray-color : #F6F9FC;
  // --label-color : #999999;
  // --hint-text-color : #CCCCCC;
  // --dark-color : #112033;
  // --green-color : #71E083;
  // --red-color : #FF7C7C;
  // --border-color: #FF5C00;
  // --border-silver-color: #C0C0C0;
  // --border-light-color: rgba(204, 204, 204, 0.5);
  // --yellow-color: #FFEAA7;
  // --input-bg-color: rgba(0, 0, 10, 0.03);
  // --black-5-color: rgba(0, 0, 0, 0.5);
  // --body-color: #6f6f6e;
  // --bg-light-color: #f7f7f7;
  // --bg-lighter: #f5f9fa;
  // --bg-secondary-dark-color: #09384e;
  // --divider-color: #eae9e9;
  // --divider-color-light: #eeeeee;
  // --divider-color-primary-light: #a1dcf9;
  // --border-color: #dbdbdb;
  // --gray-color: #979797;
  // --gray-light-color: #eae9e9;

}

$font-size-small: 13px;
$font-size-medium-small: 14px;
$font-size-regular: 16px;
$font-size-medium: 18px;
$font-size-large: 24px;
$font-size-medium-large: 26px;
$font-size-x-large: 32px;
$font-size-xx-large: 48px;

// ==========================================================================
// Config
// ==========================================================================

// Responsive Variable
$small-device-breakpoint: 979px;
$extra-small-device-breakpoint: 767px;

$menu-device: 991px;
$tablet-device: 767px;
$mobile-device: 576px;
