.blog-hero-section {
    // background-image: url('./images/freebies-hero-image.png');
    // background-position: bottom -20px right 18px;
    // background-repeat: no-repeat;
    // background-size: auto 75%;
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.blog-hero-bg-image {
    position: absolute;
    right: 50px;
    bottom: -200px;
    height: 500px;
    width: 700px;
    z-index: -1;
    .react-parallax  {
        height: 100%;
        width: 100%;
        top: 0;
        overflow: visible !important;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        right: -50px;
        bottom: -230px;
    }
    @media screen and (max-width: 767px) {
        right: 0;
        bottom: -240px;
        height: 250px;
        width: 300px;
    }
}